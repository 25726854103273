.timeclock {
  background: #df0404;
  text-align: center;
  padding: 0.2rem;
  animation: changeColor 1s infinite;
  div {
    color: white;
    font-weight: bold;
    animation: zoominoutsinglefeatured 1s infinite;
  }
}

.auction-in-proccess {
  width: max-content;
  animation: zoominoutsinglefeatured 1s infinite;
  max-width: 100%;
}

.auction-in-proccess.catalog {
  width: auto;
  position: absolute;
  right: 0.5rem;
  top: 0;
  @media screen and (max-width: 990px) {
    position: relative;
    margin-top: 1rem;
  }
}

.auction-title {
  h4 {
    margin-bottom: 0rem;
  }
  hr {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0.5rem;
  }
}

.red-status {
  color: #df0404;
}

.green-status {
  color: #7fa755;
}

.auction-status-details {
  text-align: right;
  width: max-content;
  margin-left: auto;
  @media screen and (max-width: 990px) {
    margin-left: 0;
    margin-top: 1rem;
  }
}

@keyframes changeColor {
  0% {
    background: #a10000;
  }
  50% {
    background: #df0404;
  }
  100% {
    background: #a10000;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.subastado {
  position: absolute;
  border: 2px solid #666666;
  top: 0;
  right: 0;
  color: #666666;
  font-weight: bold;
  z-index: 9;
  margin: 0 15px;
  padding: 0.5rem;
  text-align: center;
  left: 0;
  background: white;
}

.Toastify__progress-bar--default {
  background: #7fa755 !important;
}

.description-auction {
  p {
    font-size: 16px !important;
  }
}

.description-lots {
  font-size: 15px !important;
  p {
    font-size: 15px !important;
  }
}

.item-myaccount {
  margin-left: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .item-myaccount {
    margin-left: 0.5rem;
  }
}

.single-collections-box a {
  left: auto !important;
  width: auto !important;
  height: auto !important;
}

.carousel-indicators {
  .active {
    background-color: black !important;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.tabs-selection {
  font-size: 14px;
  margin-bottom: 0.5rem;
  button {
    background: transparent;
    border: none;
    &:hover {
      color: #24393e;
    }
    &:focus {
      outline: none;
    }
  }
  button.active {
    color: #24393e;
    font-weight: bold;
  }
}

/** 
 * MAximo 2 lineas la descripcion
  */
.description-lot {
  margin-bottom: 0;
  font-size: 15px;
  white-space: normal;
  height: auto;
  overflow: hidden;
}
