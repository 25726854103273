/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");

:root {
  --footer-background: #f5f5f5;
  --footer-text: #666666;
  --buttons-background: linear-gradient(
    180deg,
    rgba(4, 4, 4, 1) 0%,
    rgba(50, 50, 50, 1) 60%
  );
  --buttons-text: white;
  --buttons-border_hover: black;
  --buttons-text_hover: black;
  --headers-background_color: linear-gradient(
    180deg,
    rgba(4, 4, 4, 1) 0%,
    rgba(50, 50, 50, 1) 60%
  );
  --headers-text: white;
  --headers-text_hover: #aeaeae;
}

//$font-family: "TrebuchetMS", "Trebuchet MS";
$font-family: "Montserrat", sans-serif;
$font-size: 14px;
$paragraph-color: #666666;
$black-color: #222222;
$white-color: #ffffff;
$transition: 0.5s;

$button-color-winning: #7fa755;
$button-color-losing: #e1a900;
$button-color-losing-finished: #7e7e89;
$button-color-lost: #ff3a3a;
$button-color-not-offered: #0033ffc2;
$button-color-not-offered-animation: #082391cc;
$button-color-losing-animation: #ffc823;
$btn-close-width: 1em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $black-color;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-opacity: 0.5;
$btn-close-hover-opacity: 0.75;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 0.25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);

.quantity-container {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  input {
    width: 75px;
    text-align: center;
  }
  button {
    margin: 0rem;
    border-radius: 0;
    color: white;
    font-size: 20px;
    width: 24px;
    border-radius: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.disabled {
    background: #ccc !important;
    border: 2px solid #ccc !important;
  }
  button.red {
    border: 2px solid red;
    background: red;
  }
  button.green {
    border: 2px solid green;
    background: green;
  }
  .quantity-input {
    background: transparent;
  }
}

.primary-background {
  background-color: #24393e;
  position: relative;
}

.secondary-background {
  background-color: #f0c543;
}

.primary-color {
  color: #24393e;
}

.secondary-color {
  color: #f0c543;
}

.secondary-color-hover {
  &:hover {
    color: #f0c543;
  }
}

.yellow-separator {
  background-color: #f0c543;
  height: 10px;
  width: 50%;
  border: none;
  margin-bottom: 50px;
}

.product-info {
  list-style-type: none;
  padding: 0;
  margin: {
    top: 2rem;
    bottom: 0;
  }
  li {
    font-size: 15px;
    color: $black-color;
    margin-bottom: 2px;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: $paragraph-color;
    }
    a {
      display: inline-block;
      font-weight: 500;
      color: $black-color;

      &:hover {
        color: $black-color;
      }
    }
  }
}

.products-page-gallery {
  text-align: left !important;
}

.f-16 {
  font-size: 15px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.description-inner-html {
  p {
    font-size: 16px !important;
  }
}

.button-with-degrade {
  background: var(--buttons-background);
  border-color: transparent;
  color: var(--buttons-text);
  &:hover,
  &:focus {
    color: var(--buttons-border_hover) !important;
    border-color: var(--buttons-text_hover) !important;
    background: white !important;
  }
}

.react-confirm-alert {
  max-width: 90% !important;
  .react-confirm-alert-body {
    max-width: 100% !important;
  }
}

.react-confirm-alert-body {
  width: max-content !important;
  font-size: 1rem;
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.desktop {
  visibility: visible;
}

@media screen and (max-width: 990px) {
  .btn-mobile {
    width: 100% !important;
  }
  .desktop {
    display: none;
  }
}

.noHover {
  pointer-events: none;
  cursor: default;
}

// BUTTONS OFFER
.winning {
  color: white;
  background-color: $button-color-winning !important;
  border-color: $button-color-winning !important;
  &:hover,
  &:focus {
    color: white !important;
    background-color: $button-color-winning !important;
    border-color: $button-color-winning !important;
  }
}

.losing-finished {
  color: white;
  background-color: $button-color-losing-finished !important;
  border-color: $button-color-losing-finished !important;
  &:hover,
  &:focus {
    color: white !important;
    background-color: $button-color-losing-finished !important;
    border-color: $button-color-losing-finished !important;
  }
}

.lost {
  color: white;
  background-color: $button-color-lost !important;
  border-color: $button-color-lost !important;
  &:hover,
  &:focus {
    color: white !important;
    background-color: $button-color-lost !important;
    border-color: $button-color-lost !important;
  }
}

.losing {
  color: white;
  background-color: $button-color-losing !important;
  border-color: $button-color-losing !important;
  &:hover,
  &:focus {
    color: white !important;
    background-color: $button-color-losing !important;
    border-color: $button-color-losing !important;
  }
}

.losing_hover {
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: white !important;
    color: $button-color-losing !important;
    border-color: $button-color-losing !important;
  }
}

.losing_hover_50 {
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: #e1a9009d !important;
    color: white !important;
    border-color: #e1a9009d !important;
  }
}

.not-offered {
  color: white;
  background-color: $button-color-not-offered !important;
  border-color: $button-color-not-offered !important;
  &:hover,
  &:focus {
    color: white !important;
    background-color: $button-color-not-offered !important;
    border-color: $button-color-not-offered !important;
  }
}

.not-offered_hover {
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: white !important;
    color: $button-color-not-offered !important;
    border-color: $button-color-not-offered !important;
  }
}

.not-offered_hover_50 {
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: #0033ff71 !important;
    color: white !important;
    border-color: #0033ff71 !important;
  }
}

.not-offered-animation {
  animation: changeColorNotOffered 1s infinite;
  color: white;
}

.losing-animation {
  animation: changeColorAnimation 1s infinite;
  color: white;
}

//
@keyframes changeColorNotOffered {
  0% {
    background-color: $button-color-not-offered-animation;
    border-color: $button-color-not-offered-animation;
  }
  50% {
    background-color: $button-color-not-offered;
    border-color: $button-color-not-offered;
  }
  100% {
    background-color: $button-color-not-offered-animation;
    border-color: $button-color-not-offered-animation;
  }
}

@keyframes changeColorAnimation {
  0% {
    background-color: $button-color-losing-animation;
    border-color: $button-color-losing-animation;
  }
  50% {
    background-color: $button-color-losing;
    border-color: $button-color-losing;
  }
  100% {
    background-color: $button-color-losing-animation;
    border-color: $button-color-losing-animation;
  }
}

body {
  padding: 0;
  margin: 0;
  font: {
    family: $font-family;
    size: 15px;
  }
}

p {
  color: $paragraph-color;
  line-height: 1.8;
  font-size: $font-size;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  display: block;
  transition: $transition;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.ptb-60 {
  padding: {
    top: 60px;
    bottom: 60px;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
}

/*section-title*/
.section-title {
  text-align: center;
  padding: 15px;

  h2 {
    margin-bottom: 0;
    display: inline-block;
    font-size: 20px;
    position: relative;
    padding-left: 20px;
    font-weight: 600;

    .dot {
      position: absolute;
      top: 4px;
      left: 0;
      width: 12px;
      height: 12px;
      border: 1px solid $black-color;

      &::before {
        position: absolute;
        top: 0;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        background: $black-color;
        margin: 1px;
      }
    }
  }
  &.without-bg {
    padding: 0;
    background-color: transparent;
  }
}

/*btn(primary&light)*/
.btn {
  border: 1px solid transparent;
  padding: 0.5rem;
  line-height: initial;
  border-radius: 0;
  transition: $transition;
  text-transform: uppercase;
  font: {
    weight: 400;
    size: $font-size;
  }
  &.disabled,
  &:disabled {
    opacity: 1;
  }
}

.btn-primary {
  color: $white-color;
  background-color: rgba(127, 167, 85, 1);
  border-color: rgba(127, 167, 85, 1);

  &.disabled,
  &:disabled {
    color: $white-color;
    background-color: $black-color;
    border-color: $black-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    box-shadow: unset;
    background-color: transparent;
    color: $black-color;
    border-color: $black-color;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: transparent;
    color: rgba(127, 167, 85, 1);
    border-color: rgba(127, 167, 85, 1);
  }
}

.btn-light {
  background-color: transparent;
  color: $black-color;
  border-color: $black-color;

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $black-color;
    border-color: $black-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    box-shadow: unset;
    color: $white-color;
    background-color: $black-color;
    border-color: $black-color;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:hover,
  &:focus {
    box-shadow: unset;
    color: $white-color;
    background-color: $black-color;
    border-color: $black-color;
  }
}

/*form-control*/
.form-control {
  background-color: #f5f5f5;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  line-height: initial;
  color: $black-color;
  //border: none;
  border-radius: 0;
  transition: $transition;

  &:focus {
    outline: 0;
    background-color: #f5f5f5;
    box-shadow: unset;
  }
  &::placeholder {
    color: #999999;
  }
}

.single-product-box-new {
  color: $paragraph-color;
  margin-bottom: 10px;
  font-size: 13px;
  -webkit-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  -moz-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  border-radius: 10px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  img {
    object-fit: cover;
    width: 100%;
    max-height: 250px;
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
  }
}

.single-product-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;

  .product-image {
    position: relative;

    img {
      width: 100%;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      position: absolute;
      top: 10px;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      li {
        margin-bottom: 8px;

        a {
          width: 40px;
          height: 40px;
          line-height: 42px;
          background-color: $white-color;
          color: $black-color;
          border-radius: 50%;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

          &:hover,
          &:focus {
            color: $white-color;
            background-color: $black-color;
          }
          &.disabled {
            cursor: not-allowed;
            background-color: $white-color !important;
            color: $black-color !important;
            background: green !important;
            color: #fff !important;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sale-tag {
      position: absolute;
      top: 10px;
      left: 10px;
      background: #ff2d2d;
      color: $white-color;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      z-index: 2;
    }
    #timer {
      position: absolute;
      left: 0;
      text-align: center;
      right: 0;
      margin: 0 auto;
      bottom: 15px;

      div {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 8px 15px;
        color: $black-color;
        border-radius: 5px;
        font: {
          size: 18px;
          weight: 600;
        }
        span {
          display: block;
          font: {
            size: 13px;
            weight: normal;
          }
          color: $paragraph-color;
        }
      }
    }
  }
  .product-content {
    padding: 1rem 0rem;

    h3 {
      font-size: 16px;
      margin-bottom: 0;

      a {
        color: $black-color;

        &:hover,
        &:focus {
          color: $black-color;
        }
      }
    }
    .product-price {
      margin: {
        top: 10px;
        bottom: 10px;
      }
      span {
        font-size: 18px;
        display: inline-block;
      }
      .old-price {
        margin-right: 5px;
        color: #858585;
        font-size: 15px;
        text-decoration: line-through;
      }
    }
    .rating {
      margin-bottom: 12px;
      color: #eac11d;
      i {
        margin: 0 2px;
      }
    }
    .btn {
      display: block;
      width: 100%;
    }
  }
  &:hover,
  &:focus {
    .product-image {
      img {
        &:nth-child(2) {
          opacity: 1;
          visibility: visible;
        }
      }
      ul {
        right: 10px;
        opacity: 1;
        visibility: visible;
      }
    }
    .product-content {
      .btn-light {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}

.category-products-area {
  padding-bottom: 30px;

  &.pt-60 {
    padding-bottom: 0;
    margin-bottom: -30px;
  }
}

.single-category-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black-color;
    transition: $transition;
    opacity: 0.45;
    z-index: 2;
  }
  img {
    transition: $transition;
  }
  .category-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;

    h3 {
      margin-bottom: 0;
      color: $white-color;
      font: {
        size: 20px;
        weight: 700;
      }
    }
    .btn {
      margin-top: 22px;
    }
    .btn-light {
      background-color: transparent;
      color: $white-color;
      border-color: $white-color;

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > &.dropdown-toggle {
        color: $black-color;
        background-color: $white-color;
        border-color: $white-color;
      }
      &:hover,
      &:focus {
        color: $black-color;
        background-color: $white-color;
        border-color: $white-color;
      }
    }
  }
  .link-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }
}

.products-collections-area {
  padding-bottom: 30px;

  .container-fluid {
    width: 90%;
  }
}

.collections-box {
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-bottom: 30px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100%;
    background-color: $white-color;
    opacity: 0.4;
    transition: $transition;
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    background-color: $white-color;
    opacity: 0.4;
    transition: $transition;
    z-index: 2;
  }
  .category {
    position: absolute;
    left: 0;
    transition: $transition;
    right: 0;
    bottom: 25px;
    border: 1px solid #ececec;
    padding: 20px;
    margin: {
      left: 40px;
      right: 40px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #ededed;
      opacity: 0.94;
      z-index: 1;
      margin: 6px;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 7px;
      position: relative;
      z-index: 1;
    }
    span {
      color: $paragraph-color;
      position: relative;
      display: block;
      z-index: 1;
    }
  }
  img {
    transition: $transition;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  &:hover,
  &:focus {
    img {
      transform: scale(1.3);
    }
    &::after {
      left: 100%;
    }
    &::before {
      left: -100%;
    }
  }
}

.single-collections-box {
  overflow: hidden;
  position: relative;
  text-align: center;

  .category {
    position: absolute;
    left: 0;
    transition: $transition;
    right: 0;
    bottom: 0;
    background-color: $white-color;
    padding: 20px;
    margin: {
      left: 10px;
      right: 10px;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 7px;
      position: relative;
      z-index: 1;
    }
    span {
      color: $paragraph-color;
      position: relative;
      display: block;
      z-index: 1;
    }
  }
  img {
    transition: $transition;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  &:hover,
  &:focus {
    .category {
      bottom: 10px;
    }
  }
}

.faq-accordion {
  .accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .accordion__panel {
      ul {
        padding-left: 17px;
        margin: 0 10px;

        li {
          margin-bottom: 10px;
          line-height: 1.7;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .accordion-item {
      display: block;
      margin-bottom: 15px;
      border-bottom: 1px dashed #eeeeee;
      padding-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      .accordion-title {
        position: relative;
        color: $black-color;
        font-size: 17px;
        padding-right: 20px;

        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 14px;
        }
        &.active {
          i {
            &::before {
              content: "\f068";
            }
          }
        }
      }
      .accordion-content {
        display: none;
        margin-top: 20px;

        &.show {
          display: block;
        }
        ul {
          padding-left: 0;
          list-style-type: none;
          margin-bottom: 0;

          li {
            color: $paragraph-color;
            position: relative;
            margin-bottom: 8px;
            line-height: 25px;
            padding-left: 12px;

            &::before {
              content: "";
              position: absolute;
              top: 10px;
              left: 0;
              width: 5px;
              height: 5px;
              background: $black-color;
              border-radius: 50%;
            }
            &:last-child {
              margin-bottom: 0;
            }
            a {
              display: inline-block;
              color: $paragraph-color;

              &:hover {
                color: $black-color;
              }
            }
          }
        }
      }
    }
  }
}

.signup-area,
.login-area {
  -webkit-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  -moz-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  border-radius: 5px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 30px;
  padding: 2rem;
}

.box-subastas {
  color: $paragraph-color;
  margin-bottom: 10px;
  font-size: 13px;
  -webkit-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  -moz-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  border-radius: 1%;
  background-color: #fff;
  align-items: center;
  margin-bottom: 30px;
  padding: 1rem 0.5rem;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.contact-box {
  color: $paragraph-color;
  margin-bottom: 10px;
  font-size: 13px;
  -webkit-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  -moz-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
  border-radius: 5px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-info {
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  .contact-list {
    list-style-type: none;
    padding-left: 0;
    margin: {
      top: 20px;
      bottom: 25px;
    }
    .contact-icon {
      background-color: #f0c543;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      text-align: center;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    li {
      color: $paragraph-color;
      margin-bottom: 10px;
      font-size: 13px;
      padding-left: 20px;
      //height: 60px;
      border: 1px solid #eeeeee;
      -webkit-box-shadow: 7 4px 14px -6px #abbed1;
      -moz-box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
      box-shadow: 0 2.8px 5.6px rgba(171, 190, 209, 0.4);
      border-radius: 5px;
      background-color: #fff;
      padding: 20px 20px 20px 10px;
      align-items: center;
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        font-size: 11px;
      }

      i {
        color: $black-color;
        font-size: 20px;
      }
      a {
        color: $paragraph-color;
        display: inline-block;

        &:hover,
        &:focus {
          color: $black-color;
        }
      }
      &:last-child {
        //margin-bottom: 0;
      }
    }
  }
  .opening-hours {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 25px;
      top: 15px;
    }
    li {
      margin-bottom: 8px;
      color: $paragraph-color;
      font-size: 13px;

      span {
        color: $black-color;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .social {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 15px;
    }
    li {
      display: inline-block;
      margin-right: 4px;

      a {
        color: $paragraph-color;
        border: 1px solid #eeeeee;
        width: 35px;
        height: 35px;
        font-size: 13px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;

        &:hover,
        &:focus {
          background-color: $black-color;
          color: $white-color;
          border-color: $black-color;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.contact-form {
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  #contactForm {
    margin-top: 20px;

    label {
      font-size: 13px;
      text-transform: uppercase;
      color: $paragraph-color;

      span {
        text-transform: lowercase;
      }
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-control {
      font-size: 13px;
      height: 40px;
      border: 1px solid #eeeeee;
      transition: $transition;

      &:focus {
        border-color: $black-color;
        background-color: transparent;
      }
    }
    textarea {
      height: auto !important;
      padding-top: 15px;
    }
    .list-unstyled {
      padding-left: 0;
      list-style-type: none;
      margin: {
        bottom: 0;
        top: 8px;
      }
      li {
        font-size: 13px;
        color: red;
      }
    }
    #msgSubmit {
      margin: 0;
      font-size: 18px;

      &.text-danger,
      &.text-success {
        margin-top: 15px;
      }
    }
  }
}

#map {
  height: 400px;
  width: 100%;
}

.products-page-gallery {
  text-align: center;

  .product-page-gallery-main {
    .slick-slide {
      img {
        width: 100%;
      }
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 45px;
      height: 45px;
      line-height: 60px;
      padding: 0;
      background-color: $white-color;
      border-radius: 50%;
      text-align: center;
      transition: $transition;
      opacity: 0;
      visibility: hidden;

      &::before {
        content: "" !important;
        position: relative;
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border: 0.125rem solid;
        line-height: initial;
        opacity: 1;
        transition: $transition;
        border: {
          bottom: 0;
          left: 0;
          radius: 1px;
          color: $black-color;
        }
      }
      &:hover {
        background-color: $black-color;
        color: $white-color;

        &::before {
          border-color: $white-color;
        }
      }
    }
    .slick-next {
      padding-right: 5px;
      right: 0;

      &::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .slick-prev {
      padding-left: 5px;
      left: 0;

      &::before {
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -o-transform: rotate(225deg);
        transform: rotate(225deg);
      }
    }
    &:hover {
      .slick-prev,
      .slick-next {
        opacity: 1;
        visibility: visible;
      }
      .slick-next {
        right: 15px;
      }
      .slick-prev {
        left: 15px;
      }
    }
  }
  .slick-slide {
    outline: 0 !important;
  }
  .product-page-gallery-preview {
    margin: {
      top: 10px;
      left: -5px;
      right: -5px;
    }
    .slick-slide {
      margin: 0 5px;
      cursor: pointer;

      span {
        display: block;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px solid $black-color;
          opacity: 0;
          visibility: hidden;
          transition: $transition;
        }
        &:hover,
        &:focus {
          &::before {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &.slick-current {
        &.slick-active {
          span {
            &::before {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.product-details-content {
  h3 {
    font-size: 22px;
    margin-bottom: 8px;

    a {
      color: $black-color;

      &:hover {
        color: $black-color;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .price {
    margin-bottom: 5px;

    span {
      display: inline-block;
      font-size: 18px;
      color: $black-color;
    }
  }
  .product-review {
    margin-bottom: 12px;

    .rating {
      display: inline-block;
      padding-right: 8px;

      i {
        color: #ffba0a;
        margin-right: 2px;
      }
    }
    .rating-count {
      display: inline-block;
      color: $black-color;
      border-bottom: 1px solid $black-color;
      line-height: initial;
    }
  }

  .wishlist-compare-btn {
    margin-top: 20px;

    .btn {
      i {
        margin-right: 3px;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  .product-color-switch {
    margin-top: 8px;

    h4 {
      font-size: 16px;
      color: $paragraph-color;
      margin-bottom: 6px;
    }
    ul {
      list-style-type: none;
      margin: {
        bottom: 0;
        top: -4px;
        left: -4px;
      }
      padding: {
        left: 0;
      }
      li {
        display: inline-block;
        margin: {
          left: 4px;
          top: 4px;
        }
        a {
          display: inline-block;
          position: relative;
          border: 1px solid transparent;
          width: 25px;
          height: 25px;
          border-radius: 50%;

          &::before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #eeeeee;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
          &:hover,
          &:focus {
            border-color: $black-color;
          }
          &.color-white {
            &::before {
              background: $white-color;
              border: 1px solid #eeeeee;
            }
          }
          &.color-black {
            &::before {
              background: $black-color;
            }
          }
          &.color-red {
            &::before {
              background: red;
            }
          }
          &.color-blue {
            &::before {
              background: blue;
            }
          }
          &.color-green {
            &::before {
              background: green;
            }
          }
          &.color-yellow {
            &::before {
              background: yellow;
            }
          }
          &.color-yellowgreen {
            &::before {
              background: yellowgreen;
            }
          }
          &.color-pink {
            &::before {
              background: pink;
            }
          }
          &.color-violet {
            &::before {
              background: violet;
            }
          }
          &.color-teal {
            &::before {
              background: teal;
            }
          }
          &.color-plum {
            &::before {
              background: plum;
            }
          }
          &.color-lime {
            &::before {
              background: lime;
            }
          }
          &.color-blueviolet {
            &::before {
              background: blueviolet;
            }
          }
        }
        &.active {
          a {
            border-color: $black-color;
          }
        }
      }
    }
  }
  .product-size-wrapper {
    margin: {
      top: 8px;
      bottom: 25px;
    }
    h4 {
      font-size: 16px;
      color: $paragraph-color;
      margin-bottom: 8px;
    }
    ul {
      list-style-type: none;
      margin: {
        bottom: 0;
        top: -8px;
        left: -8px;
      }
      padding: {
        left: 0;
      }
      li {
        display: inline-block;
        margin: {
          left: 8px;
          top: 8px;
        }
        a {
          width: 40px;
          height: 35px;
          display: inline-block;
          line-height: 35px;
          border: 1px solid #eeeeee;
          color: #858585;
          text-align: center;

          &:hover,
          &:focus {
            border-color: $black-color;
            color: $black-color;
          }
        }
        &.active {
          a {
            border-color: $black-color;
            color: $black-color;
          }
        }
      }
    }
  }
  .product-add-to-cart {
    .input-counter {
      max-width: 130px;
      min-width: 130px;
      margin-right: 10px;
      text-align: center;
      display: inline-block;
      position: relative;

      span {
        position: absolute;
        top: 0;
        background-color: transparent;
        cursor: pointer;
        color: #d0d0d0;
        width: 40px;
        height: 100%;
        line-height: 50px;
        transition: $transition;

        &.minus-btn {
          left: 0;
        }
        &.plus-btn {
          right: 0;
        }
        &:hover {
          color: $black-color;
        }
      }
      input {
        height: 45px;
        color: $black-color;
        outline: 0;
        display: block;
        border: none;
        background-color: #f8f8f8;
        text-align: center;
        width: 100%;
        font: {
          size: 17px;
          weight: 600;
        }
        &::placeholder {
          color: "#7c7c7c";
          font-size: 14px;
        }
      }
    }
    .btn {
      height: 45px;
      top: -2px;
      position: relative;

      i {
        margin-right: 2px;
      }
    }
  }
  .product-info-btn {
    margin-bottom: 25px;

    a {
      color: $paragraph-color;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      position: relative;
      line-height: initial;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 0;
        height: 1.5px;
        background: $black-color;
        transition: $transition;
      }
      i {
        color: $black-color;
        margin-right: 3px;
        font-size: 15px;
      }
      &:hover {
        color: $black-color;

        &::before {
          width: 100%;
        }
      }
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
  .buy-checkbox-btn {
    margin-top: 25px;

    input {
      display: none;
    }
    .cbx {
      margin: auto;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;

      span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);

        &:first-child {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 3px;
          transform: scale(1);
          vertical-align: middle;
          border: 1px solid #ebebeb;
          transition: all 0.2s ease;
          transition: $transition;

          svg {
            position: absolute;
            top: 3px;
            left: 2px;
            fill: none;
            stroke: $white-color;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
            transform: translate3d(0, 0, 0);
            transition: $transition;
          }
          &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: $black-color;
            display: block;
            transform: scale(0);
            opacity: 1;
            border-radius: 50%;
            transition: $transition;
          }
        }
        &:last-child {
          padding-left: 10px;
          color: $paragraph-color;
        }
      }
      &:hover {
        span {
          &:first-child {
            border-color: $black-color;
          }
        }
      }
    }
    .inp-cbx:checked + .cbx span:first-child svg {
      stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
    .item {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    .btn {
      display: block;
    }
  }
  .custom-payment-options {
    margin-top: 20px;

    span {
      display: block;
      color: $paragraph-color;
      margin-bottom: 5px;
    }
    .payment-methods {
      a {
        display: inline-block;
        margin-right: 5px;
        margin-top: 5px;
        img {
          width: 40px;
        }
      }
    }
  }
}

.products-details-tab {
  margin-top: 2rem;

  .tabs {
    list-style-type: none;
    margin: 0 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center;
    padding: 0;
    border-bottom: 1px dashed #eeeeee;
    padding-bottom: 15px;

    li {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      .tabs-nav-text,
      a {
        display: inline-block;
        font-size: 14px;
        position: relative;
        padding-left: 18px;
        text-transform: uppercase;
        color: $paragraph-color;
        cursor: pointer;

        .dot {
          position: absolute;
          top: 4px;
          left: 0;
          width: 12px;
          height: 12px;
          border: 1px solid $paragraph-color;
          transition: $transition;

          &::before {
            position: absolute;
            top: 0;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            background: $paragraph-color;
            margin: 1px;
            transition: $transition;
          }
        }
        &:hover,
        &:focus {
          color: $black-color;

          .dot {
            border-color: $black-color;

            &::before {
              background: $black-color;
            }
          }
        }
      }
      &.current {
        .tabs-nav-text,
        a {
          color: $black-color;

          .dot {
            border-color: $black-color;

            &::before {
              background: $black-color;
            }
          }
        }
      }
    }
  }
  .tab_content {
    .tabs_item {
      .products-details-tab-content {
        p {
          margin-bottom: 20px;
        }
        ul,
        ol {
          padding-left: 0;
          list-style-type: none;
          margin-bottom: 0;

          li {
            margin-bottom: 10px;
            position: relative;
            padding-left: 12px;
            color: $paragraph-color;

            &::before {
              content: "";
              position: absolute;
              top: 7px;
              left: 0;
              width: 5px;
              height: 5px;
              background: $black-color;
              border-radius: 50%;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .table {
          margin-bottom: 0;

          &.table-striped {
            tbody {
              tr {
                td {
                  border-top: none;
                }
              }
            }
          }
          &.table-bordered {
            tbody {
              tr {
                td {
                  vertical-align: middle;
                  color: $paragraph-color;
                }
              }
            }
          }
        }
        h3 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .product-review-form {
          .review-title {
            position: relative;

            .rating {
              display: inline-block;
              .fas.fa-star {
                color: #ffba0a;
              }
              i {
                color: #ebebeb;
                margin-right: 2px;
              }
            }
            p {
              margin-bottom: 0;
              display: inline-block;
              padding-left: 5px;
            }
            .btn {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .review-comments {
            margin-top: 35px;

            .review-item {
              margin-top: 30px;
              position: relative;
              padding-right: 200px;
              border-top: 1px dashed #eeeeee;
              padding-top: 30px;

              .rating {
                .fas.fa-star {
                  color: #ffba0a;
                }
                i {
                  color: #ebebeb;
                }
              }
              h3 {
                margin: {
                  top: 10px;
                  bottom: 8px;
                }
              }
              span {
                margin-bottom: 10px;
                font-size: 13px;
                display: block;

                strong {
                  font-weight: 600;
                }
              }
              p {
                margin-bottom: 0;
              }
              .review-report-link {
                position: absolute;
                right: 20px;
                color: $paragraph-color;
                top: 40px;
                text-decoration: underline;

                &:hover {
                  color: $black-color;
                }
              }
            }
          }
          .review-form {
            margin-top: 35px;

            form {
              .form-group {
                label {
                  font-size: 13px;
                  color: $paragraph-color;
                  text-transform: uppercase;
                }
                textarea {
                  padding-top: 15px;
                }
              }
              .btn {
                margin-top: 10px;
              }
              .review-rating {
                margin-bottom: 1rem;

                p {
                  margin-bottom: 5px;
                }
                .star-source {
                  width: 0;
                  height: 0;
                  margin-top: 5px;
                  visibility: hidden;

                  svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    padding: 0 0.15rem;
                  }
                }
                .star-rating {
                  display: flex;
                  flex-direction: row-reverse;
                  justify-content: center;
                  width: 105px;

                  label {
                    cursor: pointer;
                    margin-bottom: 0;

                    .star {
                      color: transparent;
                      transition: color 0.2s ease-in-out;
                    }
                  }
                  label:hover ~ label .star,
                  svg.star:hover,
                  input[name="star"]:focus ~ label .star,
                  input[name="star"]:checked ~ label .star {
                    color: #f2b01e;
                  }
                  svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    padding: 0 0.15rem;
                  }
                  input {
                    &[name="star"] {
                      display: inline-block;
                      width: 0;
                      opacity: 0;
                      margin-left: -2px;
                    }
                    &[name="star"]:checked {
                      + label {
                        animation: scaleup 1s;

                        .star {
                          animation: starred 0.5s;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.login-area {
  .section-title {
    margin-bottom: 25px;
  }
}

.login-form {
  padding-right: 15px;

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    .form-control {
      border: 1px solid #f5f5f5;

      &:focus {
        border-color: $black-color;
      }
    }
  }
  .btn {
    display: block;
    width: 100%;
    padding: 14px 25px;
  }
  .forgot-password {
    display: inline-block;
    margin-top: 15px;
    color: $paragraph-color;
    position: relative;
    font-size: 15px;

    &:hover {
      color: $black-color;

      &::before {
        width: 0;
      }
    }
  }
}

.signup-area {
  .section-title {
    margin-bottom: 25px;
  }
}

.signup-content {
  margin: 0 auto;
}

.signup-form {
  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    .form-control {
      &:focus {
        border-color: $black-color;
      }
    }
  }
  .btn {
    display: block;
    width: 100%;
    padding: 14px 25px;
  }
  .return-store {
    display: inline-block;
    margin-top: 15px;
    color: $paragraph-color;
    position: relative;
    font-size: 15px;

    &::before {
      width: 100%;
      height: 1.5px;
      background: $paragraph-color;
      bottom: 0;
      left: 0;
      position: absolute;
      transition: $transition;
      content: "";
    }
    &:hover {
      color: $black-color;

      &::before {
        width: 0;
      }
    }
  }
}

.contact-banner {
  height: 500px;

  @media screen and (max-width: 768px) {
    height: 300px;
  }
}

.category-boxes-area {
  padding-bottom: 30px;
}

.product-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;

  .product-image {
    overflow: hidden;
    position: relative;

    img {
      transition: $transition;
      width: 100%;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      position: absolute;
      top: 55%;
      transform: translateY(-55%);
      right: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      margin-top: 25px;

      li {
        margin-bottom: 8px;
        display: inline-block;
        margin: {
          left: 4px;
          right: 4px;
        }
        a {
          width: 40px;
          height: 40px;
          line-height: 42px;
          background-color: $white-color;
          color: $black-color;
          border-radius: 50%;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

          &:hover,
          &:focus {
            color: $white-color;
            background-color: $black-color;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sale-tag {
      position: absolute;
      top: 10px;
      left: 10px;
      background: #ff2d2d;
      color: $white-color;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      z-index: 2;
    }
    #timer {
      position: absolute;
      left: 0;
      text-align: center;
      right: 0;
      margin: 0 auto;
      bottom: 15px;

      div {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 8px 15px;
        color: $black-color;
        border-radius: 5px;
        font: {
          size: 18px;
          weight: 600;
        }
        span {
          display: block;
          font: {
            size: 13px;
            weight: normal;
          }
          color: $paragraph-color;
        }
      }
    }
  }
  .product-content {
    margin-top: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 0;

      a {
        color: $black-color;

        &:hover,
        &:focus {
          color: $black-color;
        }
      }
    }
    .product-price {
      margin: {
        top: 8px;
        bottom: 10px;
      }
      span {
        font-size: 17px;
        display: inline-block;
      }
      .old-price {
        color: #858585;
        font-size: 15px;
        text-decoration: line-through;
      }
    }
    .rating {
      margin-bottom: 15px;
      color: #eac11d;
      i {
        margin: 0 2px;
      }
    }
    .btn {
      display: block;
      margin: {
        left: 35px;
        right: 35px;
      }
    }
  }
  &:hover,
  &:focus {
    .product-image {
      img {
        transform: scale(1.2) rotate(4deg);
      }
      ul {
        margin-top: 0;
        opacity: 1;
        visibility: visible;
      }
    }
    .product-content {
      .btn-light {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}

.category-boxes-area {
  .section-title {
    background-color: transparent;
    padding: 0;

    h2 {
      padding-left: 0;
      font-weight: 700;

      .dot {
        display: none;
      }
    }
  }
}

.list-lote {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: #24393e;
    font-size: 15px;
    font-weight: bold;

    &:last-child {
      margin-right: 0;
    }
  }
}
