.facebook-button-class {
    padding: 0.2rem 2rem;
    background: #1876f2;
    border: white;
    color: white;
    border: 1px solid transparent;
    font-family: "TrebuchetMS", "Trebuchet MS";
    i {
        margin-right: 8px;
    }
}

.facebook-button-class:hover {
    background: transparent;
    border: 1px solid #1876f2;
    color: #1876f2;
}

.desktop-login {
    visibility: visible;
}

.mobile-login {
    display: none;
}

@media screen and (max-width: 990px) {
    .desktop-login {
        display: none;
    }
    .mobile-login {
        display: contents;
    }
}

.nav-tabs-custom-login {
    width: 49%;
    text-align: center;
}
