.container-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    .content-modal {
        background: white;
        border-radius: 5px;
        padding: 1rem;
        -webkit-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        width: 500px;
        position: absolute;
        i {
            position: absolute;
            right: 1rem;
            top: 1.1rem;
            font-size: 1.2rem;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 990px) {
    .content-modal {
        width: 90% !important;
    }
}