.video-youtube {
  min-height: "450px";
  div:first-child {
    display: contents;
  }
  iframe {
    min-height: 450px;
  }
}

.carousel-home img {
  height: 600px;
  object-fit: cover;
}

.button-ingress {
  .button-ingress-link {
    width: 90%;
    margin: auto;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 1rem 0.5rem;
  }
  .button-ingress-link-right {
    background: #6f0a00;
  }
  .button-ingress-link-left {
    background: #006606;
  }
  label {
    color: #f8f4f4;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    margin: 0;
    cursor: pointer;
  }
}
