.mega-menu-navbar {
  height: 100px;
  .right {
    margin-left: auto;
  }
}

.navbar-nav {
  gap: 1rem;
}

.navbar-nav .nav-link {
  font-weight: bold !important;
}

.nav-item {
  font-weight: bold;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}

.logo {
  height: auto;
  max-width: none;
}

.animation-in-proccess {
  margin-left: 2rem;
  margin-right: 0rem !important;
  img {
    height: 4rem;
    width: auto;
  }
}

.mobile-account {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 0px !important;
  }
  .mobile-account {
    display: block;
  }
  .mega-menu-navbar {
    height: auto;
  }
  .desktop-option {
    display: none !important;
  }
  .collapse.navbar-collapse.show {
    text-align: center;
    a {
      font-size: 16px !important;
    }
    margin-left: 0px;
  }
  .logo {
    height: 40px;
  }
  .clickHere {
    height: 7.5rem !important;
    width: 7.5rem !important;
    bottom: 1rem !important;
    right: 1rem !important;
    img {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
  .animation-in-proccess {
    margin-left: 0rem;
    img {
      height: 3rem;
      width: auto;
    }
  }
  .whatsapp {
    width: 2.5rem !important;
    height: 2.5rem !important;
    font-size: 1.8rem !important;
    bottom: 10px !important;
    left: 10px !important;
  }
}

.desktop-option {
  display: block;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

@media screen and (max-width: 768px) {
  .side-collapse-container {
    width: 100%;
    position: relative;
    left: 0;
    transition: left 0.4s;
  }
  .side-collapse-container.out {
    left: 200px;
  }
  .side-collapse {
    top: 0px;
    bottom: 0;
    left: 0;
    width: 60%;
    position: fixed;
    overflow: hidden;
    transition: width 0.4s;
  }
  .side-collapse.in {
    width: 0;
  }

  .navbar-inverse {
    background-color: #24393e;
    border-color: #24393e;
    z-index: 999;
  }
}

.clickHere {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 8.5rem;
  height: 8.5rem;
  bottom: 4rem;
  right: 6rem;
  background-color: white;
  border-radius: 100%;
  text-align: center;
  z-index: 100;
  border: 0.5px solid black;
  img {
    width: 4rem;
    height: 4rem;
  }
  label {
    font-size: 12px;
    color: black;
    margin-bottom: 0;
  }
}

.animation-in-proccess {
  animation: zoominoutsinglefeatured 1s infinite;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.separator {
  margin: 0 !important;
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

.enter-button {
  background-color: #24393e;
  color: white;
  width: 200px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 3rem;
  text-align: center;
  font-size: 2.25rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
  }
}

.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 80px;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  background: #24393e;
  i {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }
  &:hover,
  &:focus {
    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }
      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

footer p {
  //width: 65%;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    width: 82%;
    font-size: 13px;
  }
}

.primary-menu {
  display: flex;
  height: 48px;
  align-items: center;
  .left {
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }
  .right {
    margin-left: auto;
  }
}

.enter-button {
  @media screen and (max-width: 768px) {
    font-size: 14px;
    max-width: 200px;
    width: max-content;
    padding: 10px 20px;
  }
}

.d-flex.d-md-none.secondary-background {
  padding-top: 0.25rem !important;
}

.d-flex.d-md-none.separator {
  height: 1px;
  background-color: #24393e;
}

.code-access-cursor {
  cursor: pointer;
}
