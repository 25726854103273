$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_one) {
  .text-mobile {
    width: 100%;
  }

  .btn {
    padding: 0.5rem;
    font-size: 12px;
  }

  .ptb-60 {
    padding: {
      top: 35px;
      bottom: 35px;
    }
  }
  .pb-60 {
    padding-bottom: 35px;
  }
  .pt-60 {
    padding-top: 35px;
  }

  .section-title {
    h2 {
      font-size: 18px;
    }
    &.without-bg {
      margin-bottom: 25px;
    }
  }

  .single-product-box {
    .product-content {
      .btn {
        display: inline-block;
        width: auto;
      }
    }
  }

  .category-products-area {
    padding-bottom: 20px;
  }
  .single-category-box {
    margin-bottom: 15px;

    .category-content {
      h3 {
        font-size: 18px;
      }
    }
    img {
      width: 100%;
    }
  }

  .products-collections-area {
    padding-bottom: 5px;
  }

  .product-details-content {
    h3 {
      font-size: 18px;
    }
    .price {
      span {
        font-size: 16px;
      }
    }
    .product-info {
      li {
        font-size: 14px;
      }
    }
    product-color-switch {
      h4 {
        font-size: 14px;
      }
    }
    .product-size-wrapper {
      h4 {
        font-size: 14px;
      }
    }
    .product-info-btn {
      a {
        display: block;

        &:not(:first-child) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
    .product-add-to-cart {
      .input-counter {
        max-width: 120px;
        min-width: 120px;
      }
    }
    .wishlist-compare-btn {
      .btn {
        text-transform: capitalize;
        padding: 0.5rem;
      }
    }
  }
  .products-details-tab {
    //margin-top: 35px;

    .tabs {
      display: block;

      li {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }
    .tab_content {
      .tabs_item {
        .products-details-tab-content {
          ol {
            margin-top: 12px;
          }
          .product-review-form {
            .review-title {
              .btn {
                position: relative;
                right: 0;
                top: 0;
                transform: unset;
                margin-top: 15px;
              }
            }
            .review-comments {
              .review-item {
                padding-right: 0;

                .review-report-link {
                  position: relative;
                  right: 0;
                  top: 0;
                  margin-top: 15px;
                  text-align: right;
                }
                h3 {
                  font-size: 17px;
                }
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .faq-accordion {
    .accordion {
      .accordion-item {
        .accordion-title {
          font-size: 15px;

          i {
            font-size: 11px;
          }
        }
      }
    }
  }

  .contact-info {
    h3 {
      font-size: 17px;
    }
    .social {
      li {
        margin-right: 2px;
      }
    }
  }
  .contact-form {
    margin-top: 30px;

    h3 {
      font-size: 17px;
    }
  }

  .footer-area {
    padding-top: 35px;
  }

  .category-boxes-area {
    padding-bottom: 5px;
  }

  .product-box {
    .product-image {
      ul {
        li {
          a {
            width: 35px;
            height: 35px;
            line-height: 36px;
            font-size: 12px;
          }
        }
      }
    }
    .product-content {
      h3 {
        font-size: 17px;
      }
      .product-price {
        span {
          font-size: 14px;
        }
      }
    }
  }

  .category-box {
    .category-content {
      top: 20px;
      left: 20px;

      h3 {
        font-size: 17px;
      }
    }
  }
  /* End home Six, Seven, Eight, Nine, Ten & Eleven CSS */

  .covid-19-banner {
    &::before {
      content: "";
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* Min width 767px to Max width 991px */
@media only #{$media} and ($feature_min : $value_two) and ($feature_max : $value_three) {
  .text-mobile {
    width: 100%;
  }

  .col-lg-4 {
    &.col-md-12 {
      &:first-child {
        .single-category-box {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .collapse-widget {
    &.aside-trending-widget {
      display: none;
    }
  }

  .contact-form {
    margin-top: 30px;
  }

  .product-details-content {
    h3 {
      font-size: 20px;
    }
    .product-info-btn {
      a {
        font-size: 12px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
    .wishlist-compare-btn {
      .btn {
        padding: 10px 20px;
        font-size: 13px;
        text-transform: capitalize;
      }
    }
  }
  .products-details-tab {
    .tab_content {
      .tabs_item {
        .products-details-tab-content {
          .product-review-form {
            .review-form {
              form .review-rating .star-rating {
                width: 170px;
              }
            }
          }
        }
      }
    }
  }

  .products-details-tab {
    margin-top: 30px;
  }
}

/* Min width 992px to Max width 1199px */
@media only #{$media} and ($feature_min : $value_four) and ($feature_max : $value_five) {
  .products-details-tab {
    margin-top: -10px;
  }
}

// Only For Mobile and Tab Navbar
@media only #{$media} and ($feature_max : $value_three) {
  .mean-container .mean-nav .navbar-nav {
    height: 350px;
    overflow-y: auto;
  }
  .navbar-light {
    .navbar-toggler {
      color: #222222;
      border-color: #222222;
      border-radius: 0;
    }
  }
  .navbar-light .navbar-toggler {
    outline: 0;
  }
}
